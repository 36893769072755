@use 'variables';
@use 'typography';

@mixin flex-justify($justify: center) {
  display: flex;
  justify-content: $justify;
  align-items: center;
}

@mixin flex-direction($direction: column) {
  display: flex;
  flex-direction: $direction;
}

@mixin card($shadow: true) {
  background-color:  theme('colors.colour-surface-primary');
  border-radius: variables.$border-radius;
  @if $shadow {
    box-shadow: variables.$shadow;
  }
}

@mixin routed-page-container() {
  padding-top: 8rem;
}

.routed-page-container {
  @include routed-page-container();
}

@mixin nav-link-primary() {
  @include typography.heading6(400);
  padding: 0.9rem 1.7rem;
  color:  theme('colors.colour-text-inactive');
  min-width: 5rem;
  border: none;
}

@mixin nav-link-primary-active() {
  @include typography.heading6();
  position: relative;
  color: theme('colors.colour-brand-ai');
  background-color:  theme('colors.colour-surface-primary');
  border-radius: 0;

  &:after {
    content: '';
    position: absolute;
    bottom: 0.2rem;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background-color: theme('colors.colour-brand-ai');
  }
}
