@use '../common';
@use 'sass:math';

$offset: 0.5rem;
$arrowHeight: 0.8rem;
$arrowWidth: 1.6rem;

$arrowHiddenHeight: 0;

:host {
  display: block;
}

ngb-tooltip-window.ui-tooltip {
  opacity: 1;
  padding: 0;
  z-index: common.getElevation(tooltips) !important;

  --backgroundColor: #{theme('colors.colour-text-inactive')};

  &.dark {
    --backgroundColor: #{theme('colors.colour-brand-ai')};
  }

  &.dark-blue {
    --backgroundColor: #{theme('colors.colour-brand-tertiary')};
  }

  .tooltip-inner {
    position: relative;
    padding: 1.1rem 1.6rem;
    background-color: var(--backgroundColor);
    border: 1px solid var(--backgroundColor);
    border-radius: 0.5rem;
    @include common.heading7();
    color: theme('colors.colour-surface-primary');
    max-width: 35rem;
  }

  &.padding-none {
    .tooltip-inner {
      padding: 0;
    }
  }

  &.padding-small {
    .tooltip-inner {
      padding: 0.2rem 0.5rem;
    }
  }

  &.padding-normal {
    .tooltip-inner {
      padding: 1.1rem 1.6rem;
    }
  }

  &.no-border {
    .tooltip-inner {
      border: 0;
    }
  }

  .tooltip-arrow {
    width: $arrowWidth;
    height: $arrowHeight;
  }

  &.hide-arrow {
    .tooltip-arrow {
      display: none;
    }
  }

  &.left-aligned {
    .tooltip-inner {
      text-align: left;
      box-shadow: common.$shadow;
    }
  }

  &.bs-tooltip-top {
    .tooltip-inner {
      bottom: $offset + $arrowHeight;
    }

    &.hide-arrow {
      .tooltip-inner {
        bottom: $offset + $arrowHiddenHeight;
      }
    }

    .tooltip-arrow {
      bottom: $offset;

      &::before {
        border-width: 1rem 1rem 0;
        border-top-color: var(--backgroundColor);
        //TODO figure out another way to center the tooltip / use angular cdk tooltip?
        //left: 0.3rem;
      }
    }
  }

  &.bs-tooltip-bottom {
    .tooltip-inner {
      top: $offset + $arrowHeight;
    }

    &.hide-arrow {
      .tooltip-inner {
        top: $offset + $arrowHiddenHeight;
      }
    }

    .tooltip-arrow {
      top: $offset;
      transform: rotate(180deg);

      &::before {
        border-width: 1rem 1rem 0;
        border-top-color: var(--backgroundColor);
        left: 0.3rem;
      }
    }
  }

  &.bs-tooltip-start {
    .tooltip-inner {
      right: $offset + $arrowHeight;
    }

    &.hide-arrow {
      .tooltip-inner {
        right: $offset + $arrowHiddenHeight;
      }
    }

    .tooltip-arrow {
      width: $arrowHeight;
      height: $arrowWidth;
      top: calc(50% - 1rem);
      right: $offset;

      &::before {
        border-width: 1rem 0 1rem 1rem;
        border-left-color: var(--backgroundColor);
      }
    }
  }

  &.bs-tooltip-end {
    .tooltip-inner {
      left: $offset + $arrowHeight;
    }

    &.hide-arrow {
      .tooltip-inner {
        left: $offset + $arrowHiddenHeight;
      }
    }

    .tooltip-arrow {
      width: $arrowHeight;
      height: $arrowWidth;
      top: calc(50% - 1rem);
      left: $offset;

      &::before {
        border-width: 1rem 1rem 1rem 0;
        border-right-color: var(--backgroundColor);
      }
    }
  }
}
