@use 'styles/src/lib/common';
.template-field-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  .formly-input {
    background-color: theme('colors.colour-surface-secondary') !important;
    border: 0.1rem solid rgb(var(--colors-colour-text-inactive) / (1 - 0.75));

    &:focus {
      outline: none !important;
      border-color: theme('colors.colour-brand-secondary') !important;
    }

    &.requested-highlight {
      border-color: theme('colors.colour-status-failure');
    }
  }
}
.custom-field-action-wrapper {
  position: relative;

  ui-icon.copy {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-0.7rem);
    cursor: pointer;
    z-index: 1;
  }
}
.readonly {
  background-color: theme('colors.colour-surface-secondary') !important;
  border: 0.1rem solid rgb(var(--colors-colour-text-inactive) / (1 - 0.75)) !important;
}
