@keyframes slide-from-left {
  0% {
    opacity: 0;
    left: -100px;
  }
  25% {
    opacity: 0.25;
    left: -50px;
  }
  50% {
    opacity: 0.5;
    left: -25px;
  }
  75% {
    opacity: 0.75;
    left: 0px;
  }
  100% {
    opacity: 1;
    left: 0px;
  }
}

@keyframes stretch-height {
  @for $i from 0 through 99 {
    #{$i}% {
      max-height: calc(#{$i} * 5px);
    }
  }
  100% {
    max-height: max-content;
  }
}
