$tablet: 768;

@mixin for-tablet {
  @media screen and (max-width: #{$tablet}px) {
    @content;
  }
}

@mixin for-smaller-than($width) {
  @media screen and (max-width: #{$width}px) {
    @content;
  }
}

@mixin for-larger-than($width) {
  @media screen and (min-width: #{$width}px) {
    @content;
  }
}

@mixin for-smaller-height-than($height) {
  @media screen and (max-height: #{$height}px) {
    @content;
  }
}

@mixin for-larger-height-than($height) {
  @media screen and (min-height: #{$height}px) {
    @content;
  }
}
