@use 'styles/src/lib/common';
// This adds line after the content like THAT ----------
.section-title {
  @include common.flex-justify(space-between);
  gap: 3rem;
  @include common.heading4();
  white-space: nowrap;

  &::after {
    content: '';
    width: 100%;
    border-bottom: 0.1rem solid rgb(var(--colors-colour-text-inactive) / (1 - 0.75));
  }
}
