@use 'styles/src/lib/common';

ui-table.collaboration-page-table {
  datatable-body-row {
    &.transparent-bg {
      height: 22px !important;
      padding: 0 !important;
      background: transparent !important;
      color: #a8a9b2;

      .datatable-body-cell-label {
        font-weight: 400 !important;
        font-size: 12px !important;
        text-transform: capitalize;

        &:first-of-type {
          margin-left: -21px !important;
        }
      }
    }
  }

  datatable-body {
    background: white;
    padding: 0 0.8rem 0.8rem;

    .empty-row {
      margin-top: 0 !important;
    }

    datatable-scroller {
      padding: 0 0.9rem 0.9rem;
      background: theme('colors.colour-surface-secondary');
      border-radius: 4px;
    }
  }

  datatable-header {
    height: 4rem !important;
    background-color: white;
    border-top: 1px solid theme('colors.colour-fill-neutral-subtle');
    border-bottom: 1px solid theme('colors.colour-fill-neutral-subtle');

    .datatable-header-cell {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;

      &:first-of-type {
        padding-left: 21px;

        .datatable-header-cell-label {
          margin-left: 19px;
        }
      }

      .datatable-header-cell-label {
        font-size: 14px !important;
        font-weight: 600 !important;
        color: theme('colors.colour-brand-secondary');
      }
    }
  }
}

.collaboration-page-filter {
  margin-bottom: 1.5rem;
  background: white;
  padding: 1.6rem;
  border-radius: 4px 4px 0 0;

  input {
    background: theme('colors.colour-surface-primary') !important;
    border-color: theme('colors.colour-fill-neutral-subtle') !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  ui-tooltip {
    right: 2.5rem !important;
  }
}
