@use 'styles/src/lib/common';
ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  .tooltip-caret.position-top {
    border-top: 7px solid transparent !important;
    border-bottom: 7px solid transparent !important;
    border-right: 7px solid transparent !important;
  }

  background-color: theme('colors.colour-brand-tertiary');
  border-radius: common.$border-radius;
}
