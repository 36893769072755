@use 'styles/src/lib/base';
@use 'styles/src/lib/common';
// Ngx scrollbar some corner case bug fix
.ng-scroll-viewport {
  display: flex;
  flex-direction: column;
}

.ng-scroll-content {
  flex: 1;
  width: 100% !important;
}

// CSS variables for configuring ng-scrollbar
ng-scrollbar.ng-scrollbar {
  --scrollbar-border-radius: #{base.$scrollbar-radius};
  --scrollbar-padding: 4px;
  --scrollbar-viewport-margin: 0;
  --scrollbar-track-color: transparent;
  --scrollbar-wrapper-color: transparent;
  --scrollbar-thumb-color: #{base.$scrollbar-color};
  --scrollbar-thumb-hover-color: var(--scrollbar-thumb-color);
  --scrollbar-size: #{base.$scrollbar-size};
  --scrollbar-hover-size: var(--scrollbar-size);
  --scrollbar-thumb-transition: height ease-out 150ms, width ease-out 150ms;
  --scrollbar-track-transition: height ease-out 150ms, width ease-out 150ms;
}

textarea {
  resize: none;
}

textarea::-webkit-scrollbar {
  width: #{base.$scrollbar-size};
}
textarea::-webkit-scrollbar-track {
  -webkit-border-radius: #{base.$scrollbar-radius};
  border-radius: #{base.$scrollbar-radius};
  background-color: #{theme('colors.colour-surface-secondary')};
}

textarea::-webkit-scrollbar-thumb {
  -webkit-border-radius: #{base.$scrollbar-radius};
  border-radius: #{base.$scrollbar-radius};
  background: #{base.$scrollbar-color};
}
