/*@font-face {*/
/*  src: url('./assets/fonts/Poppins-Light.woff') format('woff'),*/
/*  url('./assets/fonts/Poppins-Light.woff2')  format('woff2'),*/
/*  url('./assets/fonts/Poppins-Light.ttf') format('truetype');*/
/*  font-family: 'Poppins';*/
/*  font-weight: 300;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  src: url('./assets/fonts/Poppins-Regular.woff') format('woff'),*/
/*  url('./assets/fonts/Poppins-Regular.woff2')  format('woff2'),*/
/*  url('./assets/fonts/Poppins-Regular.ttf') format('truetype');*/
/*  font-family: 'Poppins';*/
/*  font-weight: 400;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Poppins';*/
/*  src: url('./assets/fonts/Poppins-SemiBold.woff') format('woff'),*/
/*  url('./assets/fonts/Poppins-SemiBold.woff2')  format('woff2'),*/
/*  url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');*/
/*  font-weight: 600;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/
/*
FUCK YOU ANGULAR CLI https://github.com/angular/angular-cli/issues/14587 WHAT IS WRONG WITH YOU!?!?!?!?!?
 */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
