@use 'styles/src/lib/common';
// Navbar
.nav-text {
  @include common.heading4();
  color: theme('colors.colour-fill-neutral-moderate');
}

// TODO: Move from here
.ui-modal {
  .modal-content {
    box-shadow: common.$shadow;
    border-radius: 0.5rem;
    border: 0;

    .modal-header {
      border: 0;
    }

    .modal-footer {
      border: 0;
    }
  }
}
