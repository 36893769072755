// styles from components library
@use 'styles/src/lib/styles';
@use 'styles/src/lib/common';
@import 'node_modules/@siemens/ngx-datatable/index.scss';
@import 'node_modules/@siemens/ngx-datatable/themes/material.scss';
@import 'node_modules/@siemens/ngx-datatable/assets/icons.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: theme('colors.colour-text-interactive');
}

.accordion {
  --bs-accordion-color: theme('colors.colour-text-primary');
}

.ng-option.ng-option-marked {
  background-color: theme('colors.colour-surface-hover') !important;
}

// classes for SVGs
.colour-surface-hover-fill {
  fill: theme('colors.colour-fill-tertiary-minimal');
}

.colour-surface-hover-stroke {
  stroke: theme('colors.colour-fill-tertiary-minimal');
}

.colour-surface-tertiary-fill {
  fill: theme('colors.colour-surface-tertiary');
}

.colour-icon-primary-fill {
  fill: theme('colors.colour-icon-primary');
}

.colour-icon-primary-stroke {
  stroke: theme('colors.colour-icon-primary');
}

.colour-icon-inactive-fill {
  fill: theme('colors.colour-icon-inactive');
}

.colour-icon-inactive-stroke {
  stroke: theme('colors.colour-icon-inactive');
}

.colour-icon-success-fill {
  fill: theme('colors.colour-icon-success');
}

.colour-icon-error-fill {
  fill: theme('colors.colour-icon-error');
}

.colour-icon-error-stroke {
  stroke: theme('colors.colour-icon-error');
}

.colour-icon-brand1-fill {
  fill: theme('colors.colour-icon-brand1');
}

.colour-icon-brand1-stroke {
  stroke: theme('colors.colour-icon-brand1');
}

.colour-icon-informative-fill {
  fill: theme('colors.colour-icon-informative');
}

.colour-icon-informative-stroke {
  stroke: theme('colors.colour-icon-informative');
}

.colour-icon-brand3-fill {
  fill: theme('colors.colour-icon-brand3');
}

.colour-icon-brand3-stroke {
  stroke: theme('colors.colour-icon-brand3');
}

.colour-icon-warning-fill {
  fill: theme('colors.colour-icon-warning');
}

.colour-fill-tertiary-subtle-fill {
  fill: theme('colors.colour-fill-tertiary-subtle');
}

.colour-fill-neutral-minimal-fill {
  fill: theme('colors.colour-fill-neutral-minimal');
}

.colour-fill-neutral-minimal-stroke {
  stroke: theme('colors.colour-fill-neutral-minimal');
}

.colour-hover-tertiary-fill {
  fill: theme('colors.colour-hover-tertiary');
}

.colour-icon-light-fill {
  fill: theme('colors.colour-icon-light');
}

.colour-icon-light-stroke {
  stroke: theme('colors.colour-icon-light');
}

.colour-fill-neutral-subtle-fill {
  fill: theme('colors.colour-fill-neutral-subtle');
}

.colour-fill-tertiary-minimal-fill {
  fill: theme('colors.colour-fill-tertiary-minimal');
}

.colour-icon-tertiary-fill {
  fill: theme('colors.colour-icon-tertiary');
}

.colour-icon-tertiary-stroke {
  stroke: theme('colors.colour-icon-tertiary');
}

.colour-fill-secondary-minimal-fill {
  fill: theme('colors.colour-fill-secondary-minimal');
}

.colour-icon-brandai-subtle-fill {
  fill: theme('colors.colour-icon-brandai-subtle');
}

.colour-fill-secondary-subtle-fill {
  fill: theme('colors.colour-icon-brandai-subtle');
}

.colour-fill-attention-subtle-fill {
  fill: theme('colors.colour-fill-attention-subtle');
}

.colour-fill-warning-subtle-fill {
  fill: theme('colors.colour-fill-warning-subtle');
}

.colour-fill-warning-subtle-fill {
  fill: theme('colors.colour-fill-warning-subtle');
}

.colour-surface-dark-strong-fill {
  fill: theme('colors.colour-surface-dark-strong');
}

.colour-icon-exception-fill {
  fill: theme('colors.colour-icon-exception');
}

.colour-brand-ai-fill {
  fill: theme('colors.colour-brand-ai');
}

.colour-surface-secondary-fill {
  fill: theme('colors.colour-surface-secondary');
}

.colour-surface-secondary-stroke {
  stroke: theme('colors.colour-surface-secondary');
}

.colour-fill-primary-strong-fill {
  fill: theme('colors.colour-fill-primary-strong');
}

.colour-fill-primary-strong-stroke {
  stroke: theme('colors.colour-fill-primary-strong');
}

//classes for SVGs - end

.user-import-modal {
  .modal-dialog {
    max-width: 34rem;
  }
}

.no-user-select,
ui-icon {
  -webkit-user-select: none;
  user-select: none;
}

.ng-scroll-viewport {
  contain: none !important;
}

.teaser-export-modal {
  .modal-dialog {
    width: 56.5rem;
    max-width: unset;
  }
}

.create-contract-modal {
  .modal-dialog {
    max-width: 35.6rem;
    max-height: 65.3rem;
  }
}

.create-contract-with-stepper-modal {
  .modal-dialog {
    max-width: 60rem;
  }

  .modal-content {
    overflow: visible !important;
  }
}

.copy-information-modal-cadr {
  .modal-dialog {
    max-width: 50rem;
  }
}

.contract-details-contract,
.create-usage-contract-modal,
.account-management-customers-modal,
.account-management-customers-modal {
  .modal-dialog {
    .modal-content {
      max-height: 75rem;
    }

    max-width: 60rem;
  }
}

.edit-customer-user-snapshot-modal {
  .modal-dialog {
    .modal-content {
      max-height: 80rem;
    }

    max-width: 60rem;
  }
}

.account-management-customers-modal {
  .modal-dialog {
    .modal-content {
      max-height: 75rem;
    }

    max-width: 100.6rem;
  }
}

.account-management-users-modal {
  .modal-dialog {
    .modal-content {
      max-height: 75rem;
    }

    max-width: 99rem;
  }
}

@include common.for-smaller-height-than(800) {
  .create-usage-contract-modal,
  .account-management-customers-modal,
  .account-management-users-modal,
  .contract-details-contract {
    .modal-dialog {
      .modal-content {
        max-height: 98%;
      }
    }
  }
}

.information-modal {
  .modal-dialog {
    min-width: 100rem;

    .modal-content {
      overflow: unset;

      .modal-header {
        padding: 1rem 3.5rem;
      }

      .modal-body {
        padding: 0;
        background: theme('colors.colour-surface-primary');
      }
    }
  }
}

.groups-sharing-modal {
  --bs-modal-width: 60rem;
}

.detach-mastercase {
  .modal-dialog {
    min-width: 57rem;
  }
}

.duplicate-case-user-modal {
  .modal-dialog {
    min-width: 90rem;

    .inner-container {
      height: 51rem;
    }

    .modal-content {
      overflow: unset;

      .modal-header {
        padding: 1rem 3.5rem;
      }

      .modal-body {
        padding: 0;
        background: theme('colors.colour-surface-primary');
      }
    }
  }
}

.application-modal,
.application-warning-modal {
  .modal-content {
    min-width: 66rem;
    height: 51rem;
  }
}

.segment-modal {
  .modal-dialog {
    min-width: 40rem;

    .modal-header {
      background: theme('colors.colour-surface-primary');
    }
  }
}

.confirmation-of-participation-withdraw {
  .modal-content {
    max-width: 40rem;
    height: 34rem;
  }
}

.upload-photo {
  .modal-content {
    max-width: 45rem;
    max-height: 53rem;
  }
}

.group-portal-actions-modal,
.group-visibility-actions-modal,
.manage-participants-access-rights {
  .modal-dialog {
    width: 63rem;
    min-width: 63rem;
  }
}

.rerequest-modal {
  .modal-dialog {
    width: 80rem;
    min-width: 80rem;
  }
}

.password-reset-modal {
  .modal-dialog {
    max-width: 49rem;
  }
}

.document-preview-modal {
  .modal-dialog {
    max-width: 90rem;
    width: 90rem;
    height: 70rem;
  }

  .modal-content {
    overflow: unset;
  }

  .modal-header {
    display: none;
  }

  .modal-footer {
    height: 8rem;
    border: none;
  }

  .modal-body {
    height: 62rem;
    position: unset;
    padding: unset;
  }
}

.video-registration-modal {
  .modal-dialog {
    max-width: 80rem;
    height: 73.5rem;
  }

  .modal-content {
    overflow: unset;
  }
}

.rounded-modal {
  .modal-content {
    border-radius: 1rem;
  }
}

.topmost-modal ~ .modal-backdrop {
  z-index: common.getElevation(topmost-modal);
}

.topmost-modal {
  z-index: common.getElevation(topmost-modal);
}

.cdk-overlay-container {
  z-index: common.getElevation(overlay-pane);

  .onboarding-overlay {
    background-color: theme('colors.colour-brand-secondary');
    opacity: 0.9;
  }
}

.large-document-preview-modal,
.large-document-preview-modal-w-neogpt {
  z-index: common.getElevation(popups);

  .modal-dialog {
    max-width: 95vw;
  }

  .modal-content {
    border-radius: 0.8rem;
    overflow: hidden;
    border: none;
  }
}
.large-document-preview-modal-w-neogpt {
  z-index: unset;
}
.advanced-pdf-edit-modal {
  z-index: common.getElevation(popups);

  .modal-dialog {
    width: 98vw;
    max-width: 160rem;
  }
}

.change-business-case-state-modal {
  ui-button {
    .btn.rounded {
      border-radius: var(--bs-border-radius-2xl) !important;
    }
  }

  .dialog-content {
    margin-bottom: 2rem !important;
  }

  & + .ng-dropdown-panel {
    top: 120%;
    width: 32.6rem;

    .ng-dropdown-panel-items .ng-option {
      padding: 1rem 1.2rem;

      &:hover {
        background-color: theme('colors.colour-surface-secondary');
      }

      &-selected {
        background-color: theme('colors.colour-surface-tertiary') !important;
      }
    }
  }
}

.collaboration-invitations-result-modal {
  .modal-dialog {
    max-width: 56rem;
    height: 60rem;
  }
}

.confirmation-modal-medium {
  .modal-dialog {
    max-width: 55rem;
  }
}

.customer-user-info-modal {
  .modal-dialog {
    max-width: 60rem;
  }
}

.select-version-for-copy-modal {
  .modal-dialog {
    max-width: 41rem;
  }
}

.page-container {
  .ng-scroll-content,
  .scroll-content {
    overflow-anchor: none;
  }
}

ng-scrollbar.ng-scrollbar {
  --scrollbar-thumb-color: theme('colors.colour-fill-neutral-moderate');
  --scrollbar-track-color: theme('colors.colour-fill-tertiary-minimal');
}

.custom-scrollbar {
  --scrollbar-thumb-hover-color: theme(
    'colors.colour-brand-secondary'
  ) !important;
  --scrollbar-size: 2px !important;

  scrollbar-y {
    .ng-scrollbar-thumb {
      background-color: theme('colors.colour-brand-secondary');
    }

    .ng-scrollbar-track {
      background-color: theme('colors.colour-surface-primary');
    }
  }
}

.scrollbar-outside-element {
  .ng-scrollbar-wrapper[deactivated='false'][dir='ltr']
    > scrollbar-y.scrollbar-control {
    right: -1.5rem;
  }
}

.create-contract-modal {
  z-index: common.getElevation(popups);

  .modal-dialog {
    max-width: 60rem;
  }
}

.table-field-modal {
  .modal-dialog {
    max-width: 45rem;
  }
}

.collaboration-invitation-modal,
.modal-collaboration-activity-modal {
  max-width: 70rem;
}

.add-circle {
  @include common.flex-justify();
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 2rem;
  background-color: theme('colors.colour-brand-ai');

  ui-icon {
    color: theme('colors.colour-surface-primary');
  }
}

.common-gray {
  color: theme('colors.colour-fill-neutral-moderate');
}

.createUserModalClass .modal-dialog {
  max-width: 80rem;
}

.data-grid-full-screen-modal {
  z-index: common.getElevation(popups);

  .modal-dialog {
    width: 98vw;
    height: 60vh;
    max-width: 160rem;

    .modal-content {
      overflow: unset;
    }
  }
}

.case-reassignment-modal {
  .modal-content {
    width: 48rem;
  }
}

ngx-datatable ::-webkit-scrollbar-thumb {
  background-color: theme('colors.colour-fill-neutral-moderate');
}

::-webkit-scrollbar-thumb {
  background-color: theme('colors.colour-fill-neutral-moderate');
}
