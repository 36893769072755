@use './variables';

html {
  font-size: variables.$font-size-base;
}

body {
  --bs-body-color: theme('colors.colour-text-primary');
  font-family: variables.$font-stack;
}

@mixin base-heading() {
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.017em;
}

@mixin heading1($weight: 600) {
  @include base-heading();

  font-size: 3rem;
  font-weight: $weight;
}

@mixin heading1-large() {
  @include base-heading();

  font-size: 4.5rem;
  font-weight: 600;
}

@mixin heading2-large($weight: 600) {
  @include base-heading();

  font-size: 2.4rem;
  font-weight: $weight;
}

@mixin heading2($weight: 600) {
  @include base-heading();

  font-size: 2rem;
  font-weight: $weight;
}

@mixin heading3() {
  @include base-heading();

  font-size: 2rem;
  font-weight: 400;
}

@mixin heading4($weight: 600) {
  @include base-heading();

  font-size: 1.6rem;
  font-weight: $weight;
}

@mixin heading5($weight: 300) {
  @include base-heading();

  font-size: 1.6rem;
  font-weight: $weight;
}

@mixin heading6($weight: 600) {
  @include base-heading();

  font-size: 1.4rem;
  font-weight: $weight;
}

@mixin heading7($weight: 300) {
  @include base-heading();

  font-size: 1.4rem;
  font-weight: $weight;
}

@mixin heading8($weight: 300) {
  @include base-heading();

  font-size: 1.2rem;
  font-weight: $weight;
}

@mixin heading9() {
  @include base-heading();

  font-size: 1.2rem;
  font-weight: 600;
}

@mixin brand-heading() {
  @include base-heading();

  font-weight: 400;
  font-size: 4.8rem;
}

.heading1,
h1 {
  @include heading1();
}

.heading2,
h2 {
  @include heading2();
}

.heading3,
h3 {
  @include heading3();
}

.heading4,
h4 {
  @include heading4();
}

.heading5,
h5 {
  @include heading5();
}

.heading6,
h6 {
  @include heading6();
}

.heading7 {
  @include heading7();
}

.heading8 {
  @include heading8();
}

.heading8-400 {
  @include heading8(400);
}

.heading9 {
  @include heading9();
}
