@use 'styles/src/lib/common';
$scrollbar-size: 0.5rem;
$scrollbar-radius: 0.7rem;
$scrollbar-color: rgba(0, 0, 0, 0.2);

::-webkit-scrollbar {
  width: $scrollbar-size;
  height: $scrollbar-size;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $scrollbar-color;
  border-radius: $scrollbar-radius;
}
