@use '../common' as c;
$border: 0.1rem solid theme('colors.colour-fill-neutral-subtle');

.refs-accordion {
  --bs-accordion-border-radius: 0.6rem;
  --bs-accordion-inner-border-radius: 0.6rem;
  --bs-accordion-active-color: theme('colors.colour-text-primary');
  --bs-accordion-active-bg: theme('colors.colour-surface-primary');
  --bs-accordion-btn-focus-box-shadow: transparent;
  --bs-accordion-border-width: 0;
  --bs-accordion-body-padding-x: 1.8rem;
  --bs-accordion-btn-icon-width: 1.6rem;

  .accordion-body {
    padding-top: 0;
  }

  .accordion-button {
    background: theme('colors.colour-surface-primary');
    box-shadow: none;
    height: 4.6rem;
  }

  .accordion-item {
    &.top-layer {
      border-radius: 0.6rem;
    }

    &:not(.top-layer) {
      border-radius: 0;

      &:not(:first-of-type) {
        border-top: 0.1rem solid theme('colors.colour-fill-neutral-subtle');
      }

      &:not(:last-of-type) {
        border-bottom: 0.1rem solid theme('colors.colour-fill-neutral-subtle');
      }
    }
  }

  .wrapping-border {
    border: 0.1rem solid theme('colors.colour-fill-neutral-subtle');
  }
}

.accordion-button-title {
  @include c.heading6();
  text-decoration: none;
}

.fields-wrapper {
  padding-bottom: 1.9rem;
  margin-bottom: 1.2rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.6rem;

  .full-span {
    grid-column: span 2;
  }

  &:not(.no-top-separator) {
    &:before {
      content: '';
      width: calc(100% + 3.6rem);
      border-top: $border;
      position: absolute;
      left: -1.8rem;
      z-index: 1;
    }
  }

  &-after:after {
    content: '';
    width: calc(100% + 3.6rem);
    border-bottom: $border;
    position: absolute;
    bottom: 0;
    left: -1.8rem;
    z-index: 1;
  }

  &.field:last-of-type {
    margin-bottom: 1rem;
  }
}

.inner-group {
  margin-bottom: 1rem;

  &-title {
    @include c.heading6();
  }

  &.accordion-item {
    border: $border;
  }

  .custom-label-checkbox .checkbox__label__text {
    @include c.heading7(300);
  }
}

.outer-group {
  display: block;
  border: none;
  margin-bottom: 1rem;
  outline: none;

  &.with-separator-above:before {
    content: '';
    display: block;
    position: relative;
    width: 100%;
    top: -1.1rem;
    height: 0.2rem;
    background: theme('colors.colour-fill-neutral-subtle');
    margin: 2rem 0 0 0;
  }

  .accordion-body {
    padding: 0 1.8rem 1.8rem;
  }
}

.inner-group,
.outer-group,
.group-sharing-outer {
  .accordion-button {
    background: theme('colors.colour-surface-primary');
    box-shadow: none;
    padding: 0 0 0 1.8rem;
    text-decoration: none;

    &-title {
      @include c.heading6();
    }

    .full-span {
      grid-column: span 2;
    }
  }
}

.inner-groups-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.6rem;
  margin-bottom: 1.6rem;

  .full-span {
    grid-column: span 2;
  }
}

.inner-group-groups {
  position: relative;
  margin-bottom: 4.6rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.6rem;

  .full-span {
    grid-column: span 2;
  }

  &:after {
    content: '';
    width: 100%;
    border-top: $border;
    position: absolute;
    margin-top: 3rem;
    z-index: 1;
  }
}

.sub-inner-group-group-title {
  @include c.heading6();
  grid-column: 1/ -1;
}

.group-sharing-outer {
  border-left: 0;
  border-right: 0;

  &:last-of-type {
    border-bottom: 0;
  }

  .accordion-button {
    padding: 0 2.5rem;
  }

  .accordion-body {
    padding: 0;
  }
}
