@use '../common' as c;

.tabs-wrapper {
  width: 100%;

  ul.nav-tabs {
    flex-wrap: unset;
    border-bottom: 0.1rem solid theme('colors.colour-fill-neutral-subtle');
    gap: 4rem;

    li.nav-li {
      a.nav-link {
        margin-bottom: unset;
        border: none;
        border-radius: 0;
        background: none;
        @include c.heading6();
        color: theme('colors.colour-fill-neutral-moderate');
        padding: 0 0 2rem 0;

        &.active {
          border-bottom: 0.3rem solid theme('colors.colour-brand-secondary');
          margin-bottom: -0.1rem;
          color: theme('colors.colour-brand-ai');
          border-color: theme('colors.colour-brand-ai');
        }
      }
    }
  }

  .tab-template-content {
    display: flex;
  }

  .tab-pane {
    width: 100%;
  }
}
