@use 'material-icons/iconfont/outlined.scss' with (
  $material-icons-font-path: './',
  $material-icons-font-size: 24px,
  $material-icons-font-display: block
);

@use 'material-icons/iconfont/filled.scss' with (
  $material-icons-font-path: './',
  $material-icons-font-size: 24px,
  $material-icons-font-display: block
);
