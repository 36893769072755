@use 'styles/src/lib/common';
.dynamic-modal-width {
  .modal-dialog {
    width: 61rem;
  }
}

.modal-header {
  justify-content: space-between;
}

// The code below fixes an issue with the z-index of the backdrop when having multiple stacked modals -> https://stackoverflow.com/a/41159276
.modal-backdrop {
  display: none;
}

.modal {
  background: rgb(var(--colors-colour-brand-secondary) / 0.7);
}
