$page-content-width: 90rem;

.page-layout-center {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 3rem;

  .page-content {
    width: $page-content-width;
  }
}

.page-layout-start {
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding-top: 8rem;

  .page-content {
    width: $page-content-width;
  }
}

$page-footer-height: 3.5rem;
